import { useState, useEffect, useMemo, } from "react";
// import reactLogo from "./assets/react.svg";
// import viteLogo from "/vite.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { FsLightboxContextProvider } from "./context/FsLightboxContext";
import Layout from "./pages/layout";
import Home from "./pages/home/Home";
import Rooms from "./pages/rooms";
import Gallery from "./pages/gallery";
import Base from './pages/utils/base'
import Navbar from "./pages/components/Navbar";
import Preloader from "./pages/components/Preloader";
import Footer from "./pages/components/Footer.jsx";
import FloatingButton from "./pages/components/FloatingButton.jsx";
// import Preloader from "./pages/components/Preloader";

import { useTranslation } from "react-i18next";






function App() {
	var base = new Base()
	const {t, i18n} = useTranslation()
	const [banner, setBanner] = useState({})
	const [arrRouter, setArrRouter] = useState({})
	const router = createBrowserRouter(
		[{
			path: "/",
			// element: <Layout/>,
			Component: Layout,
			children: [
				{
					index: true,
					// loader: Preloader,
					// element: <Home />,
					Component: Home,
					// lazy: Home
				},
				{
					// loader: Preloader,
					path: "/rooms",
					Component: Rooms,
				},
				{
					// loader: Preloader,
					path: "/gallery",
					Component: Gallery,
				},
				// {
				//   path: "todos",
				//   action: todosAction,
				//   loader: todosLoader,
				//   Component: TodosList,
				//   ErrorBoundary: TodosBoundary,
				//   children: [
				//     {
				//       path: ":id",
				//       loader: todoLoader,
				//       Component: Todo,
				//     },
				//   ],
				// },
				// {
				//   path: "deferred",
				//   loader: deferredLoader,
				//   Component: DeferredPage,
				// },
			],
		}],
		{scrollRestoration: "auto",}
	)

	if (import.meta.hot) {
		import.meta.hot.dispose(() => router.dispose());
	}

	useEffect(() => {
		const last_version = localStorage.getItem('version')
		if (last_version == null || (last_version != null && last_version !== base.app_version)) {
			localStorage.setItem('version', base.app_version)
			window.location.reload(true);
		}

		setDefaultLang()
	}, [])

	async function setDefaultLang(){
		var lang = window.localStorage.getItem('lang')
		if(lang == null){
			await window.localStorage.setItem('lang','id')
			i18n.changeLanguage('id')
		}
	}

  return (

    	<FsLightboxContextProvider>
				{
					router != null &&
					<RouterProvider router={router} />

				}

    	</FsLightboxContextProvider>
  );
}

export default App;
