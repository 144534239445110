import React, {useEffect, useState} from "react";
import { about_1, about_2, home_1, home_2 } from "../../../assets/img";
import Base from '../../utils/base'

const AboutSection = () => {
	var base = new Base()
	const [beginning, setBeginning] = useState([]);
	
	useEffect(() => {
		get_data()
	}, [])
	
	async function get_data() {
		var response = await base.request(base.url_api + "/info?type=beginning&lang=id")
	
		if(response != null){
			if(response.status == "success"){
				setBeginning(response.data)
			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}
	
  return (
    <div className="bg_white" id="about">
      <div className="container margin_120_95">
        <div className="row justify-content-between flex-lg-row-reverse align-items-center">
          <div className="col-lg-5">
            <div className="parallax_wrapper">
              <img src={beginning.url_image1} alt="" className="img-fluid rounded-img" />
              <div data-cue="slideInUp" data-delay="100" className="img_over">
                <span data-jarallax-element="-30">
                  <img src={beginning.url_image2 != null ? beginning.url_image2 : home_2} alt="" className="rounded-img" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="intro">
              <div className="title">
                <small>{beginning.subtitle1}</small>
                <h2>{beginning.title}</h2>
              </div>
              <p className="lead">
                {beginning.subtitle}
              </p>
              <p>
                {beginning.content}
              </p>
              <p>
                <em>{beginning.content1}</em>
              </p>
            </div>
          </div>
        </div>

        {/* <hr className="more_margin" /> */}
        {/* <div className="row mt-4">
          <div className="col-lg-3 col-md-6">
            <div className="box_facilities no-border" data-cue="slideInUp">
              <i className="icon-hotel-parking"></i>
              <h3>Private Parking</h3>
              <p>
                Ut enim ad minima veniam, quis nostrum exercitationem ullam
                corporis suscipit laboriosam.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="box_facilities no-border" data-cue="slideInUp">
              <i className="icon-hotel-disable"></i>
              <h3>Accessible</h3>
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="box_facilities no-border" data-cue="slideInUp">
              <i className="icon-hotel-dog"></i>
              <h3>Per Friendly</h3>
              <p>
                Similique sunt in culpa qui officia deserunt mollitia animi, id
                est laborum et dolorum fuga.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="box_facilities no-border" data-cue="slideInUp">
              <i className="icon-hotel-patio"></i>
              <h3>Patio Garden</h3>
              <p>
                Nam libero tempore, cum soluta nobis est eligendi optio cumque
                nihil impedit quo minus.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AboutSection;
