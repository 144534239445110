import $ from "jquery";
window.$ = window.jquery = $;

import React, { useEffect } from "react";
// import OwlCarousel from "react-owl-carousel";
import Loadable from "@loadable/component";
import { testimonial_bg } from "../../../assets/img";
import Base from '../../utils/base'

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

export default function VideoFullScreen(props) {
	var base = new Base()

	// useEffect()

	return (
		<div className={"position-fixed " + (props.isShow ? 'd-block' : 'd-none')} style={{ backgroundColor: 'black', zIndex: 999, height: '100vh', width: '100%', }}>
			<div className="position-relative h-100 w-100">
				<div className="position-absolute" style={{ right: 0, top: 0, }}>
					<a href="#!" onClick={() => props.onExitClicked()}>
						<i class="fa-solid fa-xmark" style={{ fontSize: '3rem', }}></i>
					</a>
				</div>

				<div className="h-100 w-100 d-flex align-items-center justify-content-center">
					<video
						loop="true"
						muted
						id="loadvideo"
						autoplay="autoplay"
						// controls
						style={{ width: '90%', height: '90%' }}
						playsInline="">
						<source src={base.host + "/image/info/file?id=INFO_20240226_000005"} type="video/mp4" />
					</video>
				</div>
			</div>
		</div>
	);
}