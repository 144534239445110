import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from './locales/en.json';
import translationID from './locales/id.json';
// import translationZHCN from './locales/zh-CN.json';
// import translationZHTW from './locales/zh-tw.json';
// import translationRU from './locales/ru.json';

const savedLanguage = localStorage.getItem('lang') || 'en';
// Create an instance of i18n
const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage, // Default language
    fallbackLng: 'en', // Fallback language if translation is missing
    // interpolation: { escapeValue: false },
  });

export default i18n;