import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const AmenitiesSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const pinnedImages = sectionRef.current.querySelectorAll(".pinned-image");

    pinnedImages.forEach((pinnedImage) => {
      const container = pinnedImage.querySelector(".pinned-image__container");
      const image = container.querySelector("img");
      const overlay = container.querySelector(
        ".pinned-image__container-overlay"
      );
      const content = pinnedImage.querySelector(".pinned_over_content");

      const tl = gsap.timeline({ paused: true });

      tl.to(
        container,
        {
          scale: 1.05,
        },
        0
      );

      tl.from(
        content,
        {
          autoAlpha: 0,
        },
        0
      );

      tl.from(
        overlay,
        {
          autoAlpha: 0,
        },
        0
      );

      ScrollTrigger.create({
        animation: tl,
        trigger: pinnedImage,
        start: "top center",
        markers: false,
        pin: false,
        scrub: false,
      });
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div ref={sectionRef} className="bg_white" id="amenities">
      <div className="container margin_120_95">
        <div className="row justify-content-between d-flex align-items-center add_bottom_90">
          <div className="col-lg-6">
            <div className="pinned-image rounded_container pinned-image--small mb-4">
              <div className="pinned-image__container">
                <img src="img/local_amenities_1.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="title">
              <small>Local Activities</small>
              <h3>Typical Food</h3>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-between d-flex align-items-center">
          <div className="col-lg-6 order-lg-2">
            <div className="pinned-image rounded_container pinned-image--small mb-4">
              <div className="pinned-image__container">
                <img src="img/local_amenities_3.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-5 order-lg-1">
            <div className="title">
              <small>Local Activities</small>
              <h3>Horse Ride</h3>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </p>
            </div>
          </div>
        </div>

        <hr className="more_margin" />

        {/* <div className="row justify-content-center">
          <div className="title text-center mb-5">
            <small data-cue="slideInUp">Local Activities</small>
            <h2 data-cue="slideInUp" data-delay="100">
              Available Tours
            </h2>
          </div>
          <div className="col-lg-8">
            <div role="tablist" className="mb-5 accordion" id="accordion_1">
              <div className="card" data-cue="slideInUp">
                <div className="card-header" role="tab">
                  <h5 className="mb-0">
                    <a
                      className="collapsed"
                      data-bs-toggle="collapse"
                      href="#collapseOne_product"
                      aria-expanded="false"
                    >
                      <i className="indicator bi-plus-lg"></i>Tour Eiffel -
                      Duration 60min
                    </a>
                  </h5>
                </div>
                <div
                  id="collapseOne_product"
                  className="collapse"
                  role="tabpanel"
                  data-bs-parent="#accordion_1"
                >
                  <div className="card-body">
                    <p>
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you
                      probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </p>
                    <p>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. 3 wolf moon officia
                      aute, non cupidatat skateboard dolor brunch. Food truck
                      quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card" data-cue="slideInUp">
                <div className="card-header" role="tab">
                  <h5 className="mb-0">
                    <a
                      className="collapsed"
                      data-bs-toggle="collapse"
                      href="#collapseTwo_product"
                      aria-expanded="false"
                    >
                      <i className="indicator bi-plus-lg"></i>Louvre Musem -
                      Duration 90min
                    </a>
                  </h5>
                </div>
                <div
                  id="collapseTwo_product"
                  className="collapse"
                  role="tabpanel"
                  data-bs-parent="#accordion_1"
                >
                  <div className="card-body">
                    <p>
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you
                      probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </p>
                    <p>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. 3 wolf moon officia
                      aute, non cupidatat skateboard dolor brunch. Food truck
                      quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card" data-cue="slideInUp">
                <div className="card-header" role="tab">
                  <h5 className="mb-0">
                    <a
                      className="collapsed"
                      data-bs-toggle="collapse"
                      href="#collapseThree_product"
                      aria-expanded="false"
                    >
                      <i className="indicator bi-plus-lg"></i>Trocadero Tour -
                      Duration 60min
                    </a>
                  </h5>
                </div>
                <div
                  id="collapseThree_product"
                  className="collapse"
                  role="tabpanel"
                  data-bs-parent="#accordion_1"
                >
                  <div className="card-body">
                    <p>
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you
                      probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </p>
                    <p>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. 3 wolf moon officia
                      aute, non cupidatat skateboard dolor brunch. Food truck
                      quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card" data-cue="slideInUp">
                <div className="card-header" role="tab">
                  <h5 className="mb-0">
                    <a
                      className="collapsed"
                      data-bs-toggle="collapse"
                      href="#collapseFour_product"
                      aria-expanded="false"
                    >
                      <i className="indicator bi-plus-lg"></i>Versailles Tour -
                      Duration 120min
                    </a>
                  </h5>
                </div>
                <div
                  id="collapseFour_product"
                  className="collapse"
                  role="tabpanel"
                  data-bs-parent="#accordion_1"
                >
                  <div className="card-body">
                    <p>
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo. Leggings occaecat craft beer
                      farm-to-table, raw denim aesthetic synth nesciunt you
                      probably haven't heard of them accusamus labore
                      sustainable VHS.
                    </p>
                    <p>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. 3 wolf moon officia
                      aute, non cupidatat skateboard dolor brunch. Food truck
                      quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AmenitiesSection;
