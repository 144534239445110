import React from 'react'
import {room_1} from '../../assets/img'
const TopBanner = () => {
  return (
    <header className="page-header" style={{backgroundImage: room_1}}>
      <div className="container h-50">
        {/* <h1>{title != null && capitalizeEachSentence(title)}</h1> */}
        <p>head</p>
      </div>
      {/* <div className="scroll-down"></div> */}
    </header>
  )
}

export default TopBanner