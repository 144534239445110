import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
// import "./index.css";
import i18n from './i18n.js'
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from 'react-redux'
import store from './redux/store'


ReactDOM.createRoot(document.getElementById("root")).render(
	<Provider store={store}>
		<React.StrictMode >
  		<I18nextProvider i18n={i18n} >
    		<App />
  		</I18nextProvider>
		</React.StrictMode>
	</Provider>
  // {/* <BrowserRouter>
  // </BrowserRouter> */}
);
