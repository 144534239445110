import React, { createContext, useState, useContext } from "react";

// Create the context
const FsLightboxContext = createContext();

// Create the context provider
export const FsLightboxContextProvider = ({ children }) => {
  const [fsToggler, setFsToggler] = useState();
  const [fsData, setFsData] = useState([]);

  return (
    <FsLightboxContext.Provider
      value={{ fsToggler, setFsToggler, fsData, setFsData }}
    >
      {children}
    </FsLightboxContext.Provider>
  );
};

// Create a custom hook to use the context
export const useFsLightboxContext = () => {
  const context = useContext(FsLightboxContext);
  if (context === undefined) {
    throw new Error(
      "useFsLightboxContext must be used within a FsLightboxContextProvider"
    );
  }
  return context;
};
