import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'
import axios from 'axios'

const homeSlice = createSlice({
	name: 'home',
	initialState: {
		value: 0,
		isShow: false,
		selectedData: {},
		index: 0,
		status: "idle",
		arr: [
			{
				id: "1",
				name: "test1",
				place: "test1",
			},
			{
				id: "2",
				name: "test2",
				place: "test2",
			},
			{
				id: "3",
				name: "test3",
				place: "test3",
			},
		],
	},
	reducers: {
		increment: state => {
			state.value++
		},
		decrement: state => {
			state.value--
		},
		incrementByAmount: (state, action) => {
			state.value += action.payload
		},
		setIndex: (state, action) => {
			state.index = action.payload
			state.selectedData = state.arr[action.payload]
		},
		setIsShow: (state, action) => {
			console.log(action)
			// state.isShow = action.payload.isShow
		},
		addArr: (state, action) => {
			state.arr.push({
				name: action.payload.name,
			})
			state.selectedData = {}
		},
		editArr: (state, action) => {
			state.arr[state.index].name = action.payload.name
			state.selectedData = {}
		},
		removeArr: (state, action) => {
			state.arr.splice(action.payload.index, 1)
			state.selectedData = {}
		},
	},
	// extraReducers(builder) {
	// 	builder
	// 		.addCase(fetchPaymentMethod.pending, (state, action) => {
	// 			state.status = 'loading'
	// 		})
	// 		.addCase(fetchPaymentMethod.fulfilled, (state, action) => {
	// 			state.status = "success"
	// 			state.arr = action.payload.data
	// 		})
	// 		.addCase(fetchPaymentMethod.rejected, (state, action) => {
	// 			state.status = "rejected"
	// 			console.log(action.payload)
	// 		})
	// }
})

export const actions = homeSlice.actions
export const selectCount = state => state.home.value
export const isShow = state => state.home.isShow
export const arrSlice = state => state.home.arr
export const indexArrSlice = state => state.home.index
export const selectedDataSlice = state => state.home.selectedData
export const statusSlice = state => state.home.status

export default homeSlice.reducer