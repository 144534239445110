import $ from "jquery";
window.$ = window.jquery = $;
import "../../../../public/js/vegas";

import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import { fotoSampulA, room_1, room_2 } from "../../../assets/img";
import Base from '../../utils/base'

// import '../../../../public/js/slider'

// const OwlCarousel = import("react-owl-carousel");

const HeroSlider = (props) => {
	var base = new Base()
	const OwlCarousel = loadable(() => import("react-owl-carousel"));
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const [slides, setSlides] = useState([]);
	const [indexActive, setIndexActive] = useState(0);
	const [fromSystem, setFromSystem] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [carouselOptions, setCarouselOptions] = useState({});
	const [interval1, setInterval1] = useState(null);

	useEffect(() => {

		$(".opacity-mask").each(function () {
			$(this).css("background-color", $(this).attr("data-opacity-mask"));
		});

		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);

		$(".owl-slide-animated").removeClass("is-transitioned")
		get_data()

		// setTimeout(() => {
		// 	await
		//
		// }, 1000)



		// return () => {
		//   window.removeEventListener("resize", handleResize);
		// };
	}, []);

	useEffect(() => {
		// setFromSystem(true)


		var arr = slides
		for(let x in arr){
			arr[x].isActive = false
			if(x == indexActive){
				arr[x].isActive = true
			}
		}

		setSlides(arr)
	}, [indexActive, ])

	useEffect(() => {

		if(slides.length > 0){
			clearTimeout(interval1)
			setInterval1(
				setTimeout(() => {
					setIndexActive(indexActive < slides.length - 1 ? indexActive + 1 : 0)
				}, 6000)
			)
		}
			// setCarouselOptions({
			// 	items: 1,
			// 	loop: true,
			// 	nav: false,
			// 	dots: true,
			// 	mouseDrag: false,
			// 	touchDrag: false,
			// 	autoplay: true,
			// 	autoplayTimeout: 10000,
			// 	autoplayHoverPause: false,
			// 	smartSpeed: 3000,
			// 	responsive: {
			// 		0: {
			// 			dots: false,
			// 		},
			// 		767: {
			// 			dots: false,
			// 		},
			// 		768: {
			// 			dots: true,
			// 		},
			// 	},
			// })
			// setTimeout(() => {
			// 	setIsLoaded(true)
			// }, 3000)
	}, [slides, fromSystem, indexActive, ])

	async function get_data() {
		var response = await base.request(base.url_api + "/banner?type="+ (windowSize.width < windowSize.height ? "mobile" : "desktop" )+"&draw=1&is_publish=1")

		if(response != null){
			if(response.status == "success"){
				setFromSystem(false)
				for(let x in response.data){
					if(x == 0)
						response.data[x].isActive = true
					response.data[x].bgImage = response.data[x].url_image
				}
				setSlides(response.data)


			}
			else
				base.show_error(response.message)
		}
		else
			console.log('Server Error')
	}

	const owlCarouselOnInitialized = () => {
		setTimeout(function () {
			$(".owl-item.active .owl-slide-animated").addClass("is-transitioned");
		}, window.location.origin == "https://theomalatretes.com" ? 3000 : 500);
	};
	const owlCarouselOnChanged = (e) => {
		$(".owl-slide-animated").removeClass("is-transitioned");
		const currentOwlItem = $(".owl-item").eq(e.item.index);
		currentOwlItem.find(".owl-slide-animated").addClass("is-transitioned");

		if(e.page.index >= 0)
			window.localStorage.setItem('bannerData', JSON.stringify(slides[e.page.index]))
	};

	function onIndicatorSelected(index){
		setIndexActive(index)
	}

	return (
		<div id="carousel-home">
			{/* <OwlCarousel
				className={"owl-carousel owl-theme " + (isLoaded ? 'owl-loaded1' : '')}
				{...carouselOptions}
				style={{ position: "relative", height: "90vh" }}
				onInitialized={owlCarouselOnInitialized}
				onChanged={owlCarouselOnChanged}
        onResize={() => setTimeout(function(){}, 50)}>
				{
					slides.map((slide) => (
						<div
							key={slide.id}
							className="owl-slide background-image cover"
							style={{
								width: "100vw",
								height: "100vh",
								backgroundImage: `url(${slide.bgImage})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
							}}>
							<div
								className={"opacity-mask " + (slide.title == '' && slide.subtitle == '' ? 'd-none' : 'd-flex align-items-center')}
								data-opacity-mask="rgba(0, 0, 0, 0.5)">
								<div className="container">
									<div className="row justify-content-center justify-content-md-start">
										<div className="col-lg-4 static">
											<div className="slide-text white">
												<small className="owl-slide-animated owl-slide-title">
													{slide.subtitle}
												</small>
												<h2 className="owl-slide-animated owl-slide-title-2">
													{slide.title}
												</h2>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))
				}
			</OwlCarousel> */}

			<div style={{ position: "relative", height: "90vh" }}>
				{
					slides.map((slide) => (
						<div
							key={slide.id}
							className={"owl-slide-animated background-image " + (slide.isActive ? 'active' : '')}
							style={{
								width: "100vw",
								height: "100vh",
								backgroundImage: `url(${slide.bgImage})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
							}}>
							<div className={"opacity-mask " + (slide.title == '' && slide.subtitle == '' ? 'd-none' : 'd-flex align-items-center')}
								data-opacity-mask="rgba(0, 0, 0, 0.5)">
								<div className="container">
									<div className="row justify-content-start justify-content-md-start">
										<div className="col-10 col-lg-4 static">
											<div className="slide-text white">
												<small className={"custom-title owl-slide-title " + (slide.isActive ? 'is-transitioned' : '')}>
													{slide.subtitle}
												</small>
												<h2 className={"custom-title owl-slide-title-2 " + (slide.isActive ? 'is-transitioned' : '')}>
													selamat datang di the omala
												</h2>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					))
				}

				<div style={{ position: "absolute", right: '2rem', height: '100%', alignItems: 'center', zIndex: 9, }} className={"d-none d-lg-flex"}>
					<div>
					{
						slides.map((slide, index) => (
							<div className="mb-1">
								<a href="#!" onClick={() => onIndicatorSelected(index)}>
									{
										slide.isActive ?
											<i class="fa-solid fa-circle" style={{ fontSize: '1rem', color: '#ffffff80', }}></i>
										:
											<i class="fa-solid fa-circle" style={{ fontSize: '1rem', color: '#77777780', }}></i>
									}
								</a>
							</div>
						))
					}
					</div>
				</div>

				<div style={{ position: "absolute", bottom: '2rem', width: '100%', justifyContent: 'center', zIndex: 9, }} className={"d-flex d-lg-none"}>
					{
						slides.map((slide, index) => (
							<div className="" style={{ marginRight: '.2rem', }}>
								<a href="#!" onClick={() => onIndicatorSelected(index)}>
									{
										slide.isActive ?
											<i class="fa-solid fa-circle" style={{ fontSize: '1rem', color: '#ffffff80', }}></i>
										:
											<i class="fa-solid fa-circle" style={{ fontSize: '1rem', color: '#77777780', }}></i>
									}
								</a>
							</div>
						))
					}
				</div>

			</div>

		</div>
	);
};

export default HeroSlider;
