// import $ from "jquery";
// window.$ = window.jquery = $;

import React, { useEffect } from "react";
// import OwlCarousel from "react-owl-carousel";
import loadable from "@loadable/component";
import { room_1, room_2 } from "../../../assets/img";

const OwlCarousel = loadable(() => import("react-owl-carousel"));
// import "../../../../public/js/slider";

const RoomsSection = () => {
  const carouselOptions = {
    center: true,
    items: 1,
    loop: false,
    addClassActive: true,
    margin: 0,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    animateOut: "fadeOut",
    responsive: {
      0: {
        dots: true,
      },
      991: {
        dots: true,
      },
    },
  };

  useEffect(() => {
    // $(".carousel_item_1").owlCarousel({
    //   center: true,
    //   items: 1,
    //   loop: false,
    //   addClassActive: true,
    //   margin: 0,
    //   autoplay: false,
    //   autoplayTimeout: 3000,
    //   autoplayHoverPause: true,
    //   animateOut: "fadeOut",
    //   responsive: {
    //     0: {
    //       dots: true,
    //     },
    //     991: {
    //       dots: true,
    //     },
    //   },
    // });
  }, []);

  return (
    <div className="container margin_120_95" id="rooms">
      <div className="row justify-content-between">
        <div className="col-xl-4 fixed_title">
          <div className="title">
            <small>Luxury experience</small>
            <h2>Our Rooms</h2>
            <p className="lead">
              Nam libero tempore, cum soluta nobis est eligendi optio cumque
              nihil impedit quo <strong>minus id quod maxime</strong> placeat
              facere possimus, omnis voluptas assumenda est, omnis dolor
              repellendu
            </p>
            <p>
              <a href="#booking_section" className="btn_1 outline">
                Book Now
              </a>
            </p>
          </div>
        </div>
        <div className="col-xl-7">
          {Array(3)
            .fill()
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className="row_list_version_3"
                  data-cue="fadeIn"
                >
                  <OwlCarousel
                    {...carouselOptions}
                    className="owl-carousel owl-theme carousel_item_1 kenburns rounded-img"
                  >
                    <div className="item">
                      <img src={room_1} alt="" />
                      {/* <a
                        data-fslightbox="rooms_1"
                        data-type="image"
                        href="img/rooms/1.jpg"
                      >
                      </a> */}
                    </div>
                    <div className="item">
                      <img src={room_2} alt="" />
                      {/* <a
                        data-fslightbox="rooms_1"
                        data-type="image"
                        href="img/rooms/opt_1.jpg"
                      >
                      </a> */}
                    </div>
                  </OwlCarousel>

                  <div className="box_item_info" data-jarallax-element="-25">
                    <small>From $150/night</small>
                    <h2>Double Room</h2>
                    <p>
                      Beautiful design with modern furnishings including a
                      glamorous bay window with your own private view of
                      Lucerne.
                    </p>
                    <div className="facilities clearfix">
                      <ul>
                        <li>
                          <i className="customicon-double-bed"></i> King Size
                          Bed
                        </li>
                        <li>
                          <i className="customicon-television"></i> 32 Inc TV
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* {roomArray.map((item, index) => {
            return <RoomItems key={index} />;
          })} */}
          {/* {roomArray.map((item, index) => {
            <div key={index} className="row_list_version_3" data-cue="fadeIn">
              <OwlCarousel
                {...carouselOptions}
                className="owl-carousel owl-theme carousel_item_1 kenburns rounded-img"
              >
                <div className="item">
                  <a
                    data-fslightbox="rooms_1"
                    data-type="image"
                    href="img/rooms/1.jpg"
                  >
                    <img src="img/rooms/1.jpg" alt="" />
                  </a>
                </div>
                <div className="item">
                  <a
                    data-fslightbox="rooms_1"
                    data-type="image"
                    href="img/rooms/opt_1.jpg"
                  >
                    <img src="img/rooms/opt_1.jpg" alt="" />
                  </a>
                </div>
              </OwlCarousel>

              <div className="box_item_info" data-jarallax-element="-25">
                <small>From $150/night</small>
                <h2>Double Room</h2>
                <p>
                  Beautiful design with modern furnishings including a glamorous
                  bay window with your own private view of Lucerne.
                </p>
                <div className="facilities clearfix">
                  <ul>
                    <li>
                      <i className="customicon-double-bed"></i> King Size Bed
                    </li>
                    <li>
                      <i className="customicon-television"></i> 32 Inc TV
                    </li>
                  </ul>
                </div>
              </div>
            </div>;
          })} */}
          {/* <div className="row_list_version_3" data-cue="fadeIn">
            <div className="owl-carousel owl-theme carousel_item_1 kenburns rounded-img">
              <div className="item">
                <a
                  data-fslightbox="rooms_2"
                  data-type="image"
                  href="img/rooms/2.jpg"
                >
                  <img src="img/rooms/2.jpg" alt="" />
                </a>
              </div>
              <div className="item">
                <a
                  data-fslightbox="rooms_2"
                  data-type="image"
                  href="img/rooms/opt_2.jpg"
                >
                  <img src="img/rooms/opt_2.jpg" alt="" />
                </a>
              </div>
            </div>

            <div className="box_item_info" data-jarallax-element="-25">
              <small>From $150/night</small>
              <h2>Double Room</h2>
              <p>
                Beautiful design with modern furnishings including a glamorous
                bay window with your own private view of Lucerne.
              </p>
              <div className="facilities clearfix">
                <ul>
                  <li>
                    <i className="customicon-double-bed"></i> King Size Bed
                  </li>
                  <li>
                    <i className="customicon-television"></i> 32 Inc TV
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row_list_version_3" data-cue="fadeIn">
            <div className="owl-carousel owl-theme carousel_item_1 kenburns rounded-img">
              <div className="item">
                <a
                  data-fslightbox="rooms_3"
                  data-type="image"
                  href="img/rooms/3.jpg"
                >
                  <img src="img/rooms/3.jpg" alt="" />
                </a>
              </div>
              <div className="item">
                <a
                  data-fslightbox="rooms_3"
                  data-type="image"
                  href="img/rooms/opt_3.jpg"
                >
                  <img src="img/rooms/opt_3.jpg" alt="" />
                </a>
              </div>
            </div>

            <div className="box_item_info" data-jarallax-element="-25">
              <small>From $230/night</small>
              <h2>Superior Room</h2>
              <p>
                Beautiful design with modern furnishings including a glamorous
                bay window with your own private view of Lucerne.
              </p>
              <div className="facilities clearfix">
                <ul>
                  <li>
                    <i className="customicon-double-bed"></i> King Size Bed
                  </li>
                  <li>
                    <i className="customicon-television"></i> 32 Inc TV
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>

        {/* {Array(10)
          .fill("test")
          .map((item, index) => {
            <div key={index}>{item}</div>;
          })} */}
      </div>
    </div>
  );
};

export default RoomsSection;
